import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../layouts/DocsLayout";
import PostNav from "./PostNav";

export default function Docs({ mdx, pageContext }) {
  const { prev, next, repositoryEditUrl, repositoryProvider } = pageContext;
  const { title, description, image, disableTableOfContents } = mdx.frontmatter;
  const { headings, body } = mdx;
  const { slug } = mdx.fields;

  return (
    <Layout
      title={title}
      headings={headings}
      disableTableOfContents={disableTableOfContents}
    >
      <div className="markdown">
        <div className="max-w-6xl">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <PostNav prev={prev} next={next} />
      </div>
    </Layout>
  );
}
